import React from 'react'

import Layout from '../components/Layout'
import Container from '../components/Container'
import Section from '../components/Section'
import Hero from '../components/Hero'

const NotFoundPage = () => (
    <Layout>
        <Hero small>
            <Container centered>
                <h1>Pagina niet gevonden</h1>
            </Container>
        </Hero>
        <Section>
            <Container>
                <p>Sorry, de pagina die u zoekt kan niet worden gevonden.</p>
            </Container>
        </Section>
    </Layout>
)

export default NotFoundPage
